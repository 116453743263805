// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./header-mobile.scss"
import { IoMdCall } from "react-icons/io"
import { IoIosMail } from "react-icons/io"

function callLogger(e) {
  typeof window !== "undefined" &&
    window.gtag("event", "click", {
      event_category: "call",
      event_action: "call",
      event_label: "call",
      event_value: 0,
    })
  //... Other logic here
  console.log("call tag fired")
}
// function smsLogger(e) {
//   typeof window !== "undefined" &&
//     window.gtag("event", "click", {
//       event_category: "sms",
//       event_action: "sms",
//       event_label: "sms",
//       event_value: 0,
//     })
//   //... Other logic here
//   console.log("sms tag fired")
// }

function emailLogger(e) {
  typeof window !== "undefined" &&
    window.gtag("event", "click", {
      event_category: "email",
      event_action: "email",
      event_label: "email",
      event_value: 0,
    })
  //... Other logic here
  console.log("email tag fired")
}

const HeaderMobile = ({ siteTitle }) => (
  <header className="header">
    <h1 className="header__title">
      <span className="header__title-spreads">SPREADS </span>
      <span className="header__title-plastering-services">
        PLASTERING SERVICES
      </span>
    </h1>
    <a
      className="header__call-icon-link"
      href="tel:+447774201918"
      onClick={e => callLogger(e)}
    >
      <IoMdCall className="header__call-icon" size="2rem" />
    </a>
    <div className="header__message">Get a quote today!</div>
    <div className="header__number">
      <a href="tel:+447774201918" onClick={e => callLogger(e)}>
        +44 777 420 1918
      </a>
    </div>
    <a
      className="header__email-icon-link"
      href="mailto:valetinepowell23@yahoo.com"
      onClick={e => emailLogger(e)}
    >
      <IoIosMail className="header__email-icon" size="2rem" />
    </a>
  </header>
)

HeaderMobile.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderMobile.defaultProps = {
  siteTitle: ``,
}

export default HeaderMobile
