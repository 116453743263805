/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import HeaderMobile from "../header-mobile/header-mobile"
import "./layout.scss"
// import "typeface-raleway"
// import "typeface-roboto"
import "typeface-open-sans"
import "typeface-poppins"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className="main">
        <HeaderMobile siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
        <footer
          style={{
            textAlign: "center",
            padding: "2rem 0",
            borderTop: "1px solid #d8d8d8",
          }}
        >
          {"Spreads Plastering Services"} © {new Date().getFullYear()}
          {/* , */}
          {/* Built by
          {` `}
          <a href="https://www.powellclark.com">EPC LTD
          </a> */}
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
